import React, { Component } from "react";
import Container from "react-bootstrap/Container";

export default class About extends Component {
  render() {
    return (
      <Container>
        <br />
        <h2>About</h2>
        <br />
        <p>
          We are four artists, two based in Wales and two in Botswana, who came
          together around shared research interests into green and sustainable
          cultures.
        </p>
        <p>
          The work here is a 'scrapbook' of our ideas and research - to provide
          a taste of the project.
        </p>
        <p>For more information email info@madeinroath.com.</p>
        <p>
          With thanks to British Council for supporting this project through the
          Go Digital fund.
        </p>
        <p>
          <a href="https://bw.linkedin.com/in/morartiwa">Moratiwa Molema</a>
          <br />
          <a href="https://www.facebook.com/media/set/?set=a.606820509420823&type=3&comment_id=607138106055730">
            Lesedi Tshenyego
          </a>{" "}
          <br />
          <a href="https://madeinroath.com">Clare Charles</a> <br />
          <a href="https://madeinroath.com">Helen Clifford</a>
        </p>
      </Container>
    );
  }
}
