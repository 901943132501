import React, { Component } from 'react'
import Artwork from './Artwork.js';
import axios from 'axios';

export default class Artworks extends Component {

  constructor() {
    super()
    this.state = {
      artworks: []
    }
  }
  componentDidMount() {
    axios.get(`https://api.airtable.com/v0/appFcmKeIfdYWLY4v/Table%201?sort%5B0%5D%5Bfield%5D=created&sort%5B0%5D%5Bdirection%5D=asc`, { headers: {"Authorization" : `Bearer keyRLxghgLdq3lJzM`} })
      .then(res => {
        const artworks = res.data.records;
        console.log(artworks)
        this.setState({ artworks });
      })
  }

  render() {
    const artworks = this.state.artworks.map((artwork) =>
      <Artwork key={artwork.id} artwork={artwork.fields} />
    )

    return (
      <div className="artworks">
        {artworks}
      </div>
    )
  }
}
