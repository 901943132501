import React, { Component } from 'react'
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class ArtPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      artwork: {}
    }
  }
  componentDidMount() {
    const { slug } = this.props.match.params;
    axios.get(`https://api.airtable.com/v0/appFcmKeIfdYWLY4v/Table%201?filterByFormula=%7Bslug%7D+%3D+%22${slug}%22`, { headers: {"Authorization" : `Bearer keyRLxghgLdq3lJzM`} })
      .then(res => {
        const artwork = res.data.records[0].fields;
        console.log(artwork)
        this.setState({ artwork });
      })
  }

  dealWithMedia1(media){
    if (media.type === "image/png" || media.type === "image/jpg" || media.type === "image/jpeg") {
      return (
        <img align="left" src={media.url} alt="Artwork" />
      )
    } else if ( media.type === "video/mp4" || media.type === "video/mov" ) {
      return (
        <video controls autoPlay muted>
          <source src={media.url} type={media.type} />
          Your browser does not support the video tag.
        </video>
      )
    } else if (media.type === "video/avi" ) {
      return null
    }   
  }

  dealWithMedia2(media){
    if (media.type === "image/png" || media.type === "image/jpg" || media.type === "image/jpeg") {
      return (
        <img style={{ maxWidth: "90%" }} src={media.url} alt="Artwork" />
      )
    } else if ( media.type === "video/mp4" || media.type === "video/mov" ) {
      return (
        <video className="video2" controls autoPlay muted>
          <source src={media.url} type={media.type} />
          Your browser does not support the video tag.
        </video>
      )
    } else if (media.type === "video/avi" ) {
      return null
    }   
  }

  render(){
    const {artwork} = this.state;

    return (
      <div className="App">
        <header className="App-header artpage">
          <Row>
            <Col style={{ textAlign: "left" }}>
              {artwork.media1 ?
              this.dealWithMedia1(artwork.media1[0])
              : null}
            </Col>
            <Col style={{ flex: "2 0 0" }}> 
              <div className="summary">
                <h1>{artwork.title}</h1>
                <h5>ARTIST: {artwork.artist}</h5>
                {artwork.medium ? <h5>MEDIUM: {artwork.medium}</h5> : null}
              </div>
            </Col>
          </Row>

          <div className="desc1">
            <hr></hr>
            <p>{artwork.description1}</p>
            <hr></hr>
          </div>
          {artwork.media2 ?
            this.dealWithMedia2(artwork.media2[0])
          : null}
          {artwork.description2 ?
            <div className="desc1">
              <p>{artwork.description2}</p>
            </div>
          : null}
          {artwork.media3 ?
            this.dealWithMedia2(artwork.media3[0])
          : null}
          <br />
          <br />
        </header>
      </div>
    )
  }

}
