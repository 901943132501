import React, { Component } from 'react'

export default class Add extends Component {

  render() {
    return (
      <React.Fragment>
        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
        <iframe title="Add an artwork" className="airtable-embed airtable-dynamic-height" 
        src="https://airtable.com/embed/shrwqP9wyMRPl2dsT?backgroundColor=green" 
        frameborder="0" onmousewheel="" width="100%" height="1656" ></iframe>
      </React.Fragment>
    )
  }
}
