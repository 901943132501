import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import {
  Link,
} from "react-router-dom";

export default class Header extends Component {
  render() {
    return (
      <Navbar style={{ backgroundColor: "#eceae4", color: "#60a696"}}>
        <Container>
          <Link to="/">{" "}</Link>
          <Nav>
            <Link className="link" to="/">Home</Link>
            <Link className="link" to="/about">About</Link>
            <Link className="link" to="/contact">Contact</Link>
          </Nav>
        </Container>
      </Navbar>
    )
  }
}
