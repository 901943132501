import React, { Component } from "react";
import Container from "react-bootstrap/Container";

export default class Contact extends Component {
  render() {
    return (
      <Container>
        <br />
        <h2>Contact</h2>
        <br />
        <p>For more information email info@madeinroath.com.</p>
      </Container>
    );
  }
}
