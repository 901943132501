import React, { Component } from 'react'
import Artworks from './Artworks.js';

export default class Home extends Component {
  constructor() {
    super()

    this.state = {
      data: [
        {
          title: "Popcorn Shells",
          url: "https://www.tate.org.uk/sites/default/files/styles/width-600/public/co22_popcornshells_1995.jpg",
          description: "The artworks he made after visiting Zimbabwe show the influence of the dotty cave paintings and the curious dung. Sometimes he stuck small balls of dung to the surface of his paintings, and often used them as little feet for his artworks to stand on. Not everyone thought that art should contain a material like this, and some people were really angry about it.",
          artist: "Chris Ofili",
          column: 8,
          slug: 'popcorn-shells'

        },
      ]
    }
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div>
            <Artworks data={this.state.data} />
          </div>
        </header>
      </div>
    )
  }
}
