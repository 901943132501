import {React, Component} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  Link,
} from "react-router-dom";

export default class Artwork extends Component {

  dealWithMedia(media, title) {
    if (media.type === "image/png" || media.type === "image/jpg" || media.type === "image/jpeg") {
      return (
        <img src={media.url} alt={title} />
      )
    } else if ( media.type === "video/mp4" || media.type === "video/mov" ) {
      return (
        <video autoPlay muted loop>
          <source src={media.url} type={media.type} />
          Your browser does not support the video tag.
        </video>
      )
    } else if (media.type === "video/avi" ) {
      return null
    }   
  }

  render(){
    const { artwork } = this.props;
    return (
      <Row className="artwork">
        <Col xs={{ offset: artwork.column - 1 }}>
          <Link to={`art/${artwork.slug}`}>
            {this.dealWithMedia(artwork.media1[0], artwork.title)}
          </Link>
        </Col>
      </Row>
    )
  }

}


