import React, { Component } from 'react'
import {
  Switch,
  Route,
} from "react-router-dom";

import './App.css';
import Header from './components/Header.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import ArtPage from './components/ArtPage';
import Add from "./components/Add";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";

export default class App extends Component {

  render() {
    return (
      <React.Fragment>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />

          <Route exact path="/add" component={Add} />
          <Route path="/art/:slug" component={ArtPage} />
        </Switch>
      </React.Fragment>
    )
  }
}

